@import '../../_variables.scss';

.preview-nav{
    .tab-cont{
        .tab-item{
            .sub{
                margin-bottom: -.2rem;
            }
            .btn-tab{
                background: none;
                color: $dblue;
                transition: all .2s ease;
                &:hover{
                  background: $lblue;
                }
                &.active{
                    background: $dblue;
                    color: $white;
                    cursor: default;

                    &:hover{
                        background: $dblue;
                        color: $white;
                    }
                }
            }
        }
    }
}