@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.work-history-cont{
    

    .work-history-input{
        border: none;

        &::placeholder{
            color: $xlgray; /* Customize the color */
        }
    }
    .work-history-item{
        border: 1px solid $xldblue;
        position: relative;

        .item-header{
            input{
                font-size: 2rem;
                font-weight: $h-fontweight;
                color: $xldblue;

                &::placeholder{
                    color: $xldblue; /* Customize the color */
                }
            }
            .btn-cont{
                display: flex;
                flex-direction: row;
                .btn-resume-item{
                    background-color: transparent;
                    color: $xldblue;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.btn-chevron{
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .item-body{
            display: flex;
            // height: 0;
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            transition: opacity .1s ease-in-out, max-height .3s ease-in-out;

            .input-cont{
                textarea{
                    padding: .5rem;
                    resize: none;
                }
            }
        }
        
        @include media-breakpoint-up(lg){
            &.active{
                border: none;
                background: $xlblue;
                .item-header{
                    input{
                        color: $dblue;
                    }
                    .btn-cont{
                        
                        .btn-resume-item{
                            color: $blue;
    
                            &.btn-chevron{
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
    
                .item-body{
                    
                    flex-direction: column;
                    height: 100%;
                    max-height: 500px;
                    opacity: 1;
                    transition: opacity .5s ease-in-out, max-height .1s ease-in-out;
    
                    .input-cont{
                        margin-bottom: 2rem;
                        textarea{
                        }
                    }
                }
            }
        }
        

        // .delete-confirm-cont{
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        // }

        // delete confirmation
        .delete-confirm-cont {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 1rem;
            border-radius: 10px;
            animation: slideIn 0.2s ease-in-out forwards;

            .delete-confirm-inner{
                padding: 1rem;
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(lg){
        .work-history-item{
            .item-header{
                padding: 5px 10px;
                input{
                    font-size: 1.2rem;
                    padding: 0;
                }
            }
            .delete-confirm-cont {
                position: relative;
            }
        }

        
    }
}