@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/maps";
@import '../_variables.scss';
.container-dash-profile{
    .profile-meta{
        h2{
            word-break: break-all;
        }
    }

    @include media-breakpoint-down(lg){
        .profile-picture{
            display: none;
        }
        .profile-meta{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h2{
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-down(sm){
        display: none;
    }
}