@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.edit-nav{
    .tab-cont{
        .tab-item{
            .btn-tab{
                background: none;
                color: $dblue;
                transition: all .2s ease;
                &:hover{
                  background: $lblue;
                }
                &.active{
                    background: $dblue;
                    color: $white;
                    cursor: default;

                    &:hover{
                        background: $dblue;
                        color: $white;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg){
        .tab-cont{
            padding: 5px;
            .tab-item{
                flex: 1 1 auto;

                .btn-tab{
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}