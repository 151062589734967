@import '../../_variables.scss';

.work-history-modal-cont{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 102vh;
    // overflow-x: hidden;
    // overflow-y: hidden;

    .header-cont{
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        border-radius: 0 0 5px 5px;
        position: fixed;
        top: 0;
        z-index: 10;

        .job-title-cont{
            h4{
                text-wrap: nowrap;
                max-width: 70vw;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .job-title-input-cont{
            input{
                background: $white;
                color: $black;
                font-size: 1rem;
                font-weight: 500;
                padding: 5px 7px;
                line-height: 1;
                width: 70vw;
            }
        }
        .close-modal{
            background: none;
            color: $red;
        }
    }
    .return-cont{
        padding-top: 6rem;
        padding-bottom: 6rem;
        // overflow-y: scroll;
        flex-grow: 1;
        // height: 100vh;
        // position: absolute;
        top: 0;
        z-index: 1;
        min-height: 100vh;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        .return-message{
        }
    }
    .description-cont{
        position: fixed;
        bottom: 0;
        z-index: 10;

        .description-item{
            position: relative;

            .description-input{
                overflow-x: hidden;
                overflow-y: scroll;
                min-height: 34px;
                max-height: 34px;
                border: none;
                word-wrap: break-word;
                resize: none;
                line-height: 1.4;
                padding: 5px 35px 5px 10px;
                font-size: 16px;
    
                &:focus{
                    outline: 2px solid $xldblue;
                    max-height: 150px;
                }
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $gray;
                    border-radius: 5px;
                }
            }
            .btn-modal-send{
                padding: 5px 7px;
                position: absolute;
                right: 7px;
                bottom: 4px;
            }
        }
        .hide-input{
            position: absolute;
            top: 0;
            width: 100%;
            background: #fff;
            height: 100%;
            left: 0;
            opacity: .6;
            backdrop-filter: blur(100px);
        }
        
    }
}