@font-face {
    font-family: 'Poppins';
    src: local('Poppins'),
         url('../fonts/Poppins-Regular.woff') format('woff'),
         url('../fonts/Poppins-Regular.woff2') format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: 'Poppins';
    src: local('Poppins'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.woff2') format('woff2');
    font-weight: 500;
}
@font-face {
    font-family: 'RobotoSlab';
    src: local('RobotoSlab'),
        url('../fonts/RobotoSlab-Regular.woff') format('woff'),
        url('../fonts/RobotoSlab-Regular.woff2') format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
}
