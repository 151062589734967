@import '../_variables.scss';

.row-pricing{
    .pricing-nav{
        
    
        .tab-cont{
            overflow: hidden;
    
            .tab-item{
                
                .btn{
                    background: $xlblue;
                    color: $dblue;
                    transition: all .2s ease;

                    &.active{
                        background: $dblue;
                        color: $white;
                    }
                }
                
                
            }
        }
    
    
    }
    
    .col-pricing{
        .pricing-card{
            .plan-features{
                .feature-item{
                    svg{
                        margin-top: .25rem;
                        color: $xldblue;
                    }
                }
                
            }
        }
    }
}
