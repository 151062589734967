@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/maps";
@import '../_variables.scss';
.section-top-bar{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;

    .top-bar-logo{
        width: 150px;
    }

    // account dropdown button
    .account-dropdown-cont{
        position: relative;

        .dropdown-toggle{
            background-color: $lblue;
            border-radius: 5px;
            padding: 2px;

            svg{
                color: $dblue;
            }

            &:hover{
                background-color: $dblue;
                cursor: pointer;
                svg{
                    color: $lblue;
                }
            }
        }
        ul.dropdown-options {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
            position: absolute;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
            padding: 0;
            right: 0;
            top: 50px;
            margin: 0;
            border-radius: 5px;
            list-style-type: none;
            z-index: 9999;

            &.open{
                opacity: 1;
                max-height: 200px; /* Set an appropriate value for the max-height */
                padding: 1rem;
            }

            li{
                text-wrap: nowrap;
            }
        }
        
        .dropdown-options.open {
            
        }
    }
    

    // signup styles
    // login styles
    .btn-exit-signup{
        border: none;
        background: none;
        color: $dblue;
    }

    @include media-breakpoint-down(lg){
        padding: 10px 15px;
    }
}