@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/maps";
@import '../_variables.scss';

footer{
    position: absolute;
    bottom: -320px;
    width: 100%;

    .footer-nav-header{
        position: relative;
        display: block;
        z-index: 2;

        &:before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            display: block;
            background: $lblue;
            position: absolute;
            left: -10px;
            top: -10px;
            z-index: 1;
            border-radius: 100%;
        }

        span{
            z-index: 2;
            position: relative;
        }
    }

    ul.footer-links{
        list-style: none;

        

        a.footer-link{
            text-decoration: none;
            margin-bottom: 5px;
            display: inline-block;
            color: $black;
            position: relative;

            &:after{
                content: "";
                display: block;
                width: 0;
                height: 1px;
                background-color: $black;
                position: absolute;
                bottom: 0;
                transition: width 0.3s ease;
            }

            &:hover{
                &:after{
                    width: 100%;
                    transition: width 0.3s ease;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        bottom: -650px;
        .footer-logo{
            max-width: 200px;
        }
        .footer-nav-header{
            font-size: 1rem;
        }
        ul.footer-links{
            a.footer-link{
                font-size: .8rem;
            }
        }
    }
}