@import "../../_variables";
.container-tabs{
    .tabs-cont{
        position: relative;
        
        &:after{
            content: "";
            height: 1px;
            width: 100%;
            background-color: $xlblue;
            position: absolute;
            bottom: 0;
        }
        .tab-header-item{
            color: $xldblue;
            background: none;
            position: relative;
            display: inline-block;
            transition: all .2s ease;

            &.active {
                color: $dblue;
                &:after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background-color: $xldblue;
                    transition: all .2s ease;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 10;
                }
            }
            &:hover{
                color: $dblue;
            }
        }
    }
}