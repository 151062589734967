@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.reset-password-form{
    
    input{
        min-width: 400px;
    }

    @include media-breakpoint-down(lg){
        .input-item{
            width: 100%;

            input{
                width: 100%;
                min-width: unset;
            }
        }
        
    }
}

.reset-password-confirmation-cont{
    transition: max-height .3s ease-in-out;
    max-height: 0;
    overflow: hidden;
    

    &.active{
        transition: max-height .3s ease-in-out;
        height: auto;
        max-height: 100px;
        
    }
    

    .reset-password-confirmation{
        border-radius: 5px;
        background-color: $xlgreen;
        color: $green;
        width: 100%;
        overflow: hidden;
        padding: .5rem;

        button{
            background: none;
            color: $green;
        }
    }
}
