@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.change-email-form{
    input{
        min-width: 400px;
    }
    .btn-cont{
        min-width: 400px;
    }

    @include media-breakpoint-down(lg){
        .input-item{
            width: 100%;
            
            input{
                width: 100%;
                min-width: unset;
            }
            .btn-cont{
                min-width: 100%;
            }
        }
        
    }
}