@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.resume-nav{
    background-color: $dblue;
    .tab-item{
        background: none;

        &.active{
            background: $white;
            color: $dblue;
        }

        &.tab-dashboard{
            background: none;

            button{
                background: none;
                padding: 0 20px 0 0;
                height: 100%;
            }
        }

        &.tab-resume{

            form{
                input{
                    padding: 0;
                    &:focus{
                        outline: none;
                    }
                }
                
            }
            .resume-title{
                max-width: 175px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .edit-icon{
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}