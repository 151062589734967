@import '../_variables.scss';
.section-signup{
    margin-top: 10vh;

    .form-signup{
        input{
            border: none;
            padding: 5px;

            &:focus{
                outline-color: $xldblue;
                outline-width: 2px;
            }

            &[type='checkbox']{
                height: 15px;
                width: 15px;
                margin-top: 3px;

                &:hover{
                    cursor: pointer;
                }
            }
        }
        label{
            &.tos{
                line-height: 1.2;
            }
        }
    }
}