@import '../../variables';

$dots: 5;
  
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    
    .dot {
      width: 10px;
      height: 10px;
      background-color: $xldblue;
      border-radius: 50%;
      margin: 0 5px;
      animation: bounce 1.1s ease infinite;
  
      @for $i from 1 through $dots {
        &:nth-child(#{$i}) {
          animation-delay: 0.10s * ($i - 1);
        }
      }
    }
}
  
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(2px);
    }
}