@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.information-cont{
    form.account-info-form{
        gap: $gap20;

        .headshot-cont{
            flex-basis: calc(25% - $gap20);

            .img-cont{
                transition: all .2s ease;
                width: 100%;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &:hover{
                    .add-icon{
                        visibility: visible;
                        opacity: 1;
                        transition: all .2s ease;
                    }
                    &:after{
                        opacity: 1;
                        visibility: visible;
                        transition: all .2s ease;
                    }
                }

                .headshot-img{
                    width: 100%;
                }
                .add-icon{
                    visibility: hidden;
                    opacity: 0;
                    color: $dblue;
                    transition: all .2s ease;
                    z-index: 10;
                    position: absolute;
                }
                &:after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $white;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .2s ease;
                }
            }
        }

        .info-cont{
            // flex-basis: calc(75% - $gap20);
            flex-basis: 100%;
            gap: $gap10;
            position: relative;

            .info-item-cont{
                flex-basis: calc(50% - $gap10);
                
                input,
                textarea{
                    border: none;
                    padding: 2px 5px;
                    border-radius: 5px;
                    &:focus{
                        outline: $xldblue auto 1px;
                    }
                }

                &.bio-cont{
                    flex-basis: calc(100% - $gap10);
                }
            }
        }

        .address-cont{
            flex-basis: 100%;
            gap: $gap10;
            position: relative;

            .info-item-cont{
                flex-basis: calc(50% - $gap10);
                
                input,
                textarea{
                    border: none;
                    padding: 2px 5px;
                    border-radius: 5px;
                    &:focus{
                        outline: $xldblue auto 1px;
                    }
                }

                &.bio-cont{
                    flex-basis: 100%;
                }
            }
        }
        
    }

    @include media-breakpoint-down(lg){
        form.account-info-form{
            .info-cont{

                .info-item-cont{
                    flex-basis: 100%;
                }

                .info-item-cont.bio-cont{
                    flex-basis: 100%;
                }
            }
            .address-cont{
                .info-item-cont{
                    flex-basis: 100%;
                }
            }
        }
    }
    
}