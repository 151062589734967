@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.billing-wrap{
    @include media-breakpoint-down(sm){
        .header-cont{
            flex-wrap: wrap;
        }
    }
}