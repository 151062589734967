$h-fontfamily: 'Poppins', sans-serif;
$h-fontweight: 500;

$p-fontfamily: 'Inter', sans-serif;
$p-fontweight: 400;

$sub-fontfamily: 'RobotoSlab', serif;
$sub-fontweight: 400;

$blue: #0038FF;
$dblue: #3532A6;
$lblue: #DADFFB;
$xlblue: #EFF1FA;
$xldblue: #8789D0;

$yellow: #FFE602;
$lyellow: #FFF6A3;

$green: #4B890D;
$xlgreen: #F2FFE5;

$white: #ffffff;
$dwhite: #f8f8f8;
$black: #000000;

$red: #AA0000;
$lred: #FFB8B8;
$xlred: #FFE0E0;

$black: #000000;
$white: #ffffff;

$lgray: #D3D3D3;
$gray: #808080;
$xlgray: #E6E6E6;

$theme-colors: (
  "primary":    $dblue,
  "secondary":  $lblue,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
);

$custom-colors:(
  "blue": $blue,
  "dblue": $dblue,
  "lblue": $lblue,
  "xlblue": $xlblue,
  "xldblue": $xldblue,
  "yellow": $yellow,
  "lyellow": $lyellow,
  "red": $red,
  "lred": $lred,
  "xlred": $xlred
);
$theme-colors: map-merge($theme-colors, $custom-colors);

$spacer: .625rem;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
);

$border-radius:               .45rem;
$border-radius-sm:            .625rem;
$border-radius-lg:            1.25rem;


$gap10: 10px;
$gap20: 20px;
$gap30: 30px;


// $custom-colors:map-merge($theme-colors, (
//   "blue": $blue,
//   "l-blue": $l-blue,
//   "xl-blue": $xl-blue,
//   "xl-dblue": $xl-dblue
// ));