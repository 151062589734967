@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/maps";
@import '../../_variables.scss';

.modal-verify-email{
    position: fixed;
    top: 30%;
    background: $xlblue;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    flex-direction: column;
    z-index: 9999;
    left: calc(50vw - 200px);

    .warning-icon{
        fill: $red;
    }
    .warning-message{
        text-wrap: balance;
    }
}