
html{
  font-size: 16px;
}
body{
  &.modal-open{
    overflow: hidden;

    &:after{
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      background: #000;
      opacity: .5;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}
#root{
  min-height: 100vh;
  position: relative;
}
h1{
  font-family: $h-fontfamily;
  font-weight: $h-fontweight;
  font-size: 3rem;
}
h2{
  font-family: $h-fontfamily;
  font-weight: $h-fontweight;
  font-size: 2.5rem;
}
h3{
  font-family: $h-fontfamily;
  font-weight: $h-fontweight;
  font-size: 2rem;
}
h4{
  font-family: $h-fontfamily;
  font-weight: $h-fontweight;
  font-size: 1.75rem;
}
h5{
  font-family: $h-fontfamily;
  font-weight: $h-fontweight;
  font-size: 1.25rem;
}
p{
  font-family: $p-fontfamily;
  font-weight: $p-fontweight;
  font-size: 1rem;
}
.sub{
  font-family: $sub-fontfamily;
  font-weight: $sub-fontweight;
  margin-bottom: 0;
  line-height: .8;
  font-size: 1rem;

  &.sub-sm{
      font-size: .75rem;
      line-height: 1;
      margin-bottom: 5px;;
  }
}
.section-title{
  margin-bottom: 0;
  line-height: 1;  
}
a{

  &.inline-link{
    color: $blue;
    text-decoration: underline;
    transition: all .2s ease;

    &:hover{
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(lg){
  h1{
    font-size: 2.5rem;
  }
  h2{
    font-size: 2rem;
  }
  h3{
    font-size: 1.75rem;
  }
  h4{
    font-size: 1.5rem;
  }
  h5{
    font-size: 1.25rem;
  }
  p{
    font-size: .9rem;
  }
  .sub{
    font-size: .9rem;
  }
}

@include media-breakpoint-down(md){
  h1{
    font-size: 2rem;
  }
  h2{
    font-size: 1.75rem;
  }
  h3{
    font-size: 1.5rem;
  }
  h4{
    font-size: 1.25rem;
  }
  h5{
    font-size: 1rem;
  }
  p{
    font-size: .8rem;
  }
  .sub{
    font-size: .8rem;
  }
}

////////////////////////////////////////////
/// buttons
button{
  border: none;
  font-family: $p-fontfamily;
  font-size: 1rem;
  font-weight: $p-fontweight;
  text-transform: capitalize;

  &.btn-inline{
    position: relative;
    text-decoration: none;
    color: $blue;
    background: none;
    display: flex;
    align-items: center;
    align-self: flex-end;
    

    &:after{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $blue;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: width .2s ease;
    }
    &.text-red{
      &:after{
        background-color: $red;
      }
    }

    &:hover{
      text-decoration: none;

      &:after{
        width: 0;
      }
    }

    svg{
      margin-right: 5px;
    }
  }
  &.btn-icon{
    svg{
      margin-right: 5px;
    }
  }
}

.btn{
  position: relative;
  font-family: $p-fontfamily;
  font-weight: $p-fontweight;
  font-size: 1rem;
  padding: 5px 40px;
  border: none;
  line-height: 1.4;
  border-radius: 5px;

  @include media-breakpoint-down(xl){
    padding: 5px 20px;
  }
  
}

.btn-primary{
  background-color: $dblue;
  color: $white;
  border-radius: 5px;
  transition: all .2s ease;

  &:hover{
    background-color: $white;
    color: $dblue;
  }
}

.btn-xlblue{
  background: $xlblue;
  color: $dblue;
  transition: all .2s ease;

  &:hover{
    background: $dblue;
    color: $white;
  }
}

.btn-blue{
  background: $blue;
  color: $white;
  transition: all .2s ease;

  &:hover{
    background: $yellow;
    color: $black;

    &:after{
      background-color: transparent;
    }
  }
}

.btn-xlred{
  background: $xlred;
  color: $red;
  transition: all .2s ease;

  &:hover{
    background: $red;
    color: $white;
  }
}
.btn-xlgreen{
  background: $xlgreen;
  color: $green;
  transition: all .2s ease;

  &:hover{
    background: $green;
    color: $xlgreen;
  }
}

.btn-accent-yellow{
  &:after{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: $yellow;
    position: absolute;
    top: -7px;
    left: -7px;
    border-radius: 20px;
    transition: all .2s ease;
  }
}





////////////////////////////////////////////
/// utilities
.br-5{
  border-radius: 5px;
}
.br-10{
  border-radius: 10px;
}
.br-15{
  border-radius: 15px;
}
.br-20{
  border-radius: 20px;
}
  

.divider-bottom-lblue{
  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $lblue;
    margin: 5px 0;
    position: relative;
  }
}

.bg-noise{
  background-image: url("../../assets/images/bg-noise.png");
  background-repeat: repeat;
  background-color: $dwhite;
}

.hide{
  display: none;
}

.bg-none{
  background: none;
}


////////////////////////////////////////////
/// inputs
input{
  border: none;
  border-radius: 2px;
  padding: 5px 10px;

  &:focus{
    outline: 2px solid $xldblue;
  }

  &[disabled]{
    background-color: $xlgray;
    color: $gray;
  }
}