@import '../../_variables.scss';

.work-history-return-cont{
    border: 1px solid $xldblue;
    overflow: hidden;
    max-height: 80px;

    .work-history-item{
        

        .item-header{
            h4{
                font-size: 1.25rem;
            }
            
        }
        .item-body{
            display: none;
            height: 0;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            padding: 0;
            visibility: hidden;
            scroll-behavior: smooth;
            overflow-y: scroll;
        }
        .item-preview{
            transition: all 0.2s ease-in-out;
            opacity: 1;
            padding: .8rem;
        }
    }


    &.active{
        max-height: unset;
        .work-history-item{
            height: 303px;
            
            .item-body{
                height: calc(100% - 33px);
                overflow-y: scroll;
                display: flex;
                // justify-content: flex-end;
                opacity: 1;
                flex-grow: 1;
                padding: .625rem .625rem 0 .625rem;
                visibility: visible;

                .item-returns{
                    flex-direction: column-reverse;
                }
                
            }
            .item-preview{
                height: 0;
                opacity: 0;
                visibility: hidden;
                padding: 0;
            }
        }
        
    }

    .item-header{
        border-bottom: 1px solid $xldblue;
    }
}