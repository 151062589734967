@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/maps";
@import '../_variables.scss';

.container-dash-resumes{
    .resume-card{
        position: relative;
        text-decoration: none;
        color: $black;
        overflow: hidden;
        cursor: pointer;
        transition: all .3s ease;

        &:hover{
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }

        .card-header{
            .header-text{
                max-width: 90%;
                .title{
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            
            .btn-delete-resume{
                color: $white;
                background: none;
                border-radius: 5px;
                transition: all .2s ease;

                &:hover{
                    color: $red;
                    background: $xlred;
                }
            }
        }
        

        .card-body{
            max-height: 160px;
            overflow-y: scroll;

            /* width */
            &::-webkit-scrollbar {
                width: 5px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: none;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $lblue;
                transition: all .2s ease;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                transition: all .2s ease;
                background: $dblue;
            }

            ul{
                margin-bottom: 0;
                padding-left: 20px;
            }
        }

        // delete confirmation
        .delete-confirm-cont {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 1rem;
            border-radius: 10px;
            animation: slideIn 0.2s ease-in-out forwards;

            .delete-confirm-inner{
                padding: 1rem;
                text-align: center;
            }
        }
          
        @keyframes slideIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
        }
    }

    @include media-breakpoint-down(md){
        .row-header{
            .col{
                justify-content: space-between;
            }
            
        }
    }
}